import { Model } from '@vuex-orm/core'
// import User from "../../user/services/User.js";

export default class Auth extends Model {
	static entity = 'auth'
	static apiConfig = {
		headers: {'X-Requested-With': 'XMLHttpRequest'},
		baseURL: '/api/auth',
		dataKey: 'auth',
	}

	static fields () {
		return {
			id: this.attr(null),
			rolesList: this.attr([]),
			prenom: this.attr(''),
			lightyShareCertifed: this.attr(false),
			statutUser: this.attr(''),
			mensualisation: this.attr(null),
			createdAtUnix: this.attr(null),
			userSwitch: this.attr(null),
		}
	}
}
